export const environment = {
  production: false,

  clientName: 'pti',
  apiGatewayFront: 'https://agp.pti-sa.com.co/',
  apiGatewayBackOffice: 'https://agp.pti-sa.com.co/',
  apiUrl: 'https://agp.pti-sa.com.co/',
  urlPath: 'https://agp.pti-sa.com.co/',
  customCss: false,
  // Whether or not to enable debug mode
  // Setting this to false will disable console output
  enableDebug: false,
  // siteKeyCaptcha: '6LdLtBsgAAAAAJksLT4W-TI-oRed7Uk9B8Y-EECB', DEMO
  // siteKeyCaptcha: '6LfL5fkfAAAAALmrMTjEg18wnQm5uR3ZNbBy-pL7' ZX

  siteKeyCaptcha: '6LdhNQkqAAAAABT0OyRmnijXQXZx6JtAqCYAdfu8',

  googleAnalyticsCode: 'UA-140785247-1',
};